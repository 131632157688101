import React, { useEffect, useMemo, useRef, useState } from "react";
import { GET_PINS, getAxios } from "../../util/util";
import { Marker, Pane, useMapEvent } from "react-leaflet";
import { DivIcon, LatLng, LeafletEvent, Map, ZoomPanOptions } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { SHOW_ZOOM } from "../../App";

interface Location {
  type: "Point";
  coordinates: [number, number];
};

interface Pin {
  id: number;
  plots: number;
  location: Location;
  zoom_location: Location;
};

const Pins = (): JSX.Element | null => {
  const [data, setData] = useState<Pin[] | null>(null);
  const paneRef = useRef<HTMLElement>(null);

  useEffect((): void => {
    getAxios()
      .get<Pin[]>(GET_PINS)
      .then((res): void => {
        setData(res.data);
      });
  }, []);

  const map: Map = useMapEvent("zoomend", (it: LeafletEvent): void => {
    if ((it.target as Map).getZoom() >= SHOW_ZOOM) {
      paneRef.current!!.classList.add("hidden");
    } else {
      paneRef.current!!.classList.remove("hidden");
    }
  });

  const initiallyHidden = useMemo((): string => {
    return map.getZoom() >= SHOW_ZOOM ? "hidden" : "";
  }, []);

  if (data === null) {
    return null;
  }

  const zoomOptions: ZoomPanOptions = {
    animate: true,
    duration: 1.5,
  };

  return (
    <Pane className={initiallyHidden} ref={paneRef} name="pin">
      {data.map((it: Pin): JSX.Element => {
        const icon: DivIcon = new DivIcon({
          html: renderToStaticMarkup(
            <>
              <div className="pin-bg" />
              <span className="pin-text">{it.plots}</span>
            </>
          ),
        });
        return (
          <Marker
            key={it.id}
            icon={icon}
            pane="pin"
            eventHandlers={{
              click: (): void => {
                const target: LatLng = new LatLng(
                  it.zoom_location.coordinates[0],
                  it.zoom_location.coordinates[1]
                );
                map.setView(target, SHOW_ZOOM, zoomOptions);
              },
            }}
            position={it.location.coordinates}
          />
        );
      })}
    </Pane>
  );
};
export default Pins;
