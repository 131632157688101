import * as React from 'react';
import {AppBar, Toolbar} from '@mui/material';
import {useTranslation} from "react-i18next";
import Infobox from './info/Info';
import Menu from './menu/Menu';
import zIndexes from '../util/styles/zIndexes';
import {LANG_EN, LANG_UK} from "../util/util";

const MainBar = (): JSX.Element => {
    const language: string = useTranslation().i18n.language;

    return <AppBar
        position="fixed"
        color="primary"
        sx={{
            zIndex: zIndexes.values.mainBar,
            backgroundImage: 'url(/images/menu_background.svg)'
        }}
    >
        <Toolbar>
            <Infobox/>
            <div style={{height: '50px', flexGrow: 1, marginLeft: '10px'}}>
                <img alt='Logo' src={'/images/logo_' + (language.includes(LANG_EN) ? LANG_EN : LANG_UK) + '.svg'}
                     style={{height: '100%'}}/>
            </div>
            <Menu/>
        </Toolbar>
    </AppBar>
}
export default MainBar;
