import { MapContainer, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import All from "./map/empty/All";

import "./App.css";
// @ts-ignore
import ShowData from "./debug/ShowData";
import Selection from "./map/selection/Selection";
import { Toaster } from "react-hot-toast";
import TileLayerSwitch from "./map/tile/TileLayerSwitch";
import OrderModal from "./main_bar/order/OrderModal";
import { Loader } from "./context/Loader";
import Sold from "./map/sold/Sold";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PlotContextProvider from "./context/PlotContext";
import MainBar from "./main_bar/MainBar";
import React, { useMemo, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Feedback from "./page/Feedback";
import { LatLng } from "leaflet";
import AuthContext from "./context/AuthContext";
import Pins from "./map/pin/Pins";
import { isLocalEnvironment } from "./util/util";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { isSafari } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DEFAULT_ZOOM: number = 15;
const SHOW_ZOOM: number = 15;
const MIN_ZOOM: number = 7;
const DEFAULT_CENTER: [number, number] = [55.750303, 37.6185];
export { DEFAULT_ZOOM, SHOW_ZOOM, DEFAULT_CENTER };
  

function App(): JSX.Element {
  // Ссылка на конкретную область с конкретным зумом
  const { zoom, center } = useMemo((): { zoom: number; center: LatLng } => {
    const params: URLSearchParams = new URLSearchParams(
      document.location.search
    );
    const zoom: number = params.has("s")
      ? parseInt(params.get("s")!!)
      : DEFAULT_ZOOM;

    let center: LatLng = new LatLng(DEFAULT_CENTER[0], DEFAULT_CENTER[1]);
    if (params.has("c")) {
      const split: number[] = params
        .get("c")!
        .split("-")
        .map((it: string): number => parseFloat(it));
      if (split.length === 2 && !split.some((it) => isNaN(it))) {
        center = new LatLng(split[0], split[1]);
      }
    }
    return { zoom, center };
  }, []);

  const [successOpen, setSuccessOpen] = useState<boolean>(
    document.location.hash === "#success"
  );

  const closeSuccess = (): void => {
    setSuccessOpen(false);
    if (!isSafari) {
      const urlWithoutHash: string = window.location.href.split("#")[0];
      window.history.replaceState(null, "", urlWithoutHash);
    }
  };

  const { t } = useTranslation();

  if (window.location.search.includes("?f=true&key=")) {
    return <Feedback />;
  }

  

  return (
    <>
      {/* popup */}
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      <Toaster />
      <Dialog open={successOpen} onClose={closeSuccess}>
        <DialogContent>
          <Typography>{t("payment_success.text")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSuccess}>{t("payment_success.close")}</Button>
        </DialogActions>
      </Dialog>
      <MapContainer
        zoomControl={false}
        center={center}
        minZoom={MIN_ZOOM}
        zoom={zoom}
        style={{ height: "100vh" }}
      >
        <React.Suspense
          fallback={
            <Backdrop style={{ zIndex: 9000 }} open={true}>
              <CircularProgress />
            </Backdrop>
          }
        >
          <Loader>
            {/* Капча */}
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_KEY!!}
            >
              {/* Купленные и выделенные клетки */}
                <PlotContextProvider>
                  <AuthContext>
                    {/* Header c Меню, формой обратной связи и общей информацией, приветственная информация. */}
                    <MainBar />
                    {/* Модалка для оплаты */}
                    <OrderModal />
                  </AuthContext>
                  {/* Селектор участков */}
                  <Selection defaultZoom={zoom} />
                  {/* Проданные участки */}
                  <Sold defaultZoom={zoom} />
                  {/* Непроданные участки */}
                </PlotContextProvider>
            </GoogleReCaptchaProvider>
          </Loader>
          {/* Все клетки */}
          <All defaultZoom={zoom} />
          {/* Дебаг */}
          {isLocalEnvironment && <ShowData />}
        </React.Suspense>
        {/* Переключение между видом карты и местности */}
        <TileLayerSwitch />
        <Pins />
        <ZoomControl position="bottomright" />
      </MapContainer>
    </>
  );
};

export default App;
