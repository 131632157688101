import {
    Box,
    CircularProgress,
    CircularProgressProps,
    IconButton,
    Menu,
    MenuItem,
    TableCell,
    TableRow
} from "@mui/material";
import Button from "@mui/material/Button";
import {ContentCopy, Download, LocationSearchingOutlined, Share} from "@mui/icons-material";
import toast from "react-hot-toast";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import React, {useState} from "react";
import {Order} from "../../util/types/commonTypes";
import {useTranslation} from "react-i18next";
import {apiAddress, downloadPDF} from "../../util/util";
import Typography from "@mui/material/Typography";
import {round} from "lodash";
import {useMap} from "react-leaflet";
import {LatLng} from "leaflet";
import {SHOW_ZOOM} from "../../App";

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }): JSX.Element {
    return <Box sx={{position: 'relative', display: 'inline-flex'}}>
        <CircularProgress variant="determinate" {...props}/>
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="caption"
                component="div"
                color="text.secondary"
            >
                {`${round(props.value)}%`}
            </Typography>
        </Box>
    </Box>
}

interface Props {
    order: Order,
    closeModal: () => void
}

const shareIconSize: number = 25

const OrderRow = ({order, closeModal}: Props): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const [download, setDownload] = useState<boolean>(false)
    const [downloadProgress, setDownloadProgress] = useState<number>(0)

    const {t} = useTranslation()
    const map = useMap()

    const downloadCertificate = (): void => {
        setDownload(true)
        toast.loading(t('plot_list.downloading'), {duration: 3500})

        downloadPDF(apiAddress + `/order/certificate?key=${order.key}&id=${order.id}`, (val: number): void => {
            setDownloadProgress(val)
        }).finally((): void => {
            setDownload(false)
            setDownloadProgress(0)
            toast.success(t('plot_list.downloaded'))
        })
    }

    const showPlot = (): void => {
        map.setZoom(SHOW_ZOOM)
        map.setView(new LatLng(order.plot.center[0], order.plot.center[1]))
        closeModal()
    }

    const downloadButton = (): JSX.Element => {
        if (!download) {
            return <IconButton color='primary' onClick={downloadCertificate}>
                <Download/>
            </IconButton>
        }
        if (downloadProgress === 0) {
            return <CircularProgress/>
        }
        return <CircularProgressWithLabel variant='determinate' value={downloadProgress}/>
    }

    return <TableRow>
        <TableCell sx={{textAlign: 'center'}}>{order.id}</TableCell>
        <TableCell sx={{textAlign: 'center'}}>{order.payable_size}</TableCell>
        <TableCell sx={{textAlign: 'center'}}>
            <Button onClick={showPlot} startIcon={<LocationSearchingOutlined/>}>Show</Button>
        </TableCell>
        <TableCell sx={{textAlign: 'center'}}>
            {downloadButton()}
        </TableCell>
        <TableCell sx={{textAlign: 'center'}}>
            <IconButton
                onClick={e => setAnchorEl(e.currentTarget)}
                aria-controls={anchorEl !== null ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl !== null ? 'true' : undefined}
                color='primary'
            >
                <Share/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    navigator.clipboard.writeText(order.plot.link).then(() => toast.success(t('copied')))
                }}><ContentCopy/></MenuItem>
                <MenuItem>
                    <FacebookShareButton url={order.plot.link}>
                        <FacebookIcon size={shareIconSize}/>
                    </FacebookShareButton>
                </MenuItem>
                <MenuItem>
                    <TwitterShareButton url={order.plot.link}>
                        <TwitterIcon size={shareIconSize}/>
                    </TwitterShareButton>
                </MenuItem>
                <MenuItem>
                    <TelegramShareButton url={order.plot.link}>
                        <TelegramIcon size={shareIconSize}/>
                    </TelegramShareButton>
                </MenuItem>
                <MenuItem>
                    <WhatsappShareButton url={order.plot.link}>
                        <WhatsappIcon size={shareIconSize}/>
                    </WhatsappShareButton>
                </MenuItem>
                <MenuItem>
                    <LinkedinShareButton url={order.plot.link}>
                        <LinkedinIcon size={shareIconSize}/>
                    </LinkedinShareButton>
                </MenuItem>
            </Menu>
        </TableCell>
    </TableRow>
}

export default OrderRow
