const jwtName: string = 'key'

export const getJWT = (): string | null => {
    return localStorage.getItem(jwtName) || null
}

export const setJWT = (value: string): void => {
    localStorage.setItem(jwtName, value)
}

export const removeJWT = (): void => {
    localStorage.removeItem(jwtName)
}
