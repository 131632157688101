import { featureCollection, FeatureCollection, polygon } from "@turf/turf";
import { max, min } from "lodash";
import Decimal from "decimal.js";

/**
 * @see {squareGrid}
 */
interface CreateGridOptions {
  round?: (a: Decimal) => Decimal;
  limit?: boolean;
  inside?: FeatureCollection;
  outside?: FeatureCollection;
  accurate?: boolean;
}

/**
 * @see Grid
 */
interface GridOptions {
  id?: number;
  color?: string;
  active?: boolean;
  z_index?: number;
  comment?: string;
}

interface LatLng {
  lat: number;
  lng: number;
}

interface BBOXInaccurate extends Array<number> {
  0: number;
  1: number;
  2: number;
  3: number;
}

/* Decimal.js используется для рассчетов соприкосновения участков при выделении */
interface BBOXAccurate extends Array<Decimal> {
  0: Decimal;
  1: Decimal;
  2: Decimal;
  3: Decimal;
}

export const MAX_PLOT_SIZE: number = 45;

/**
 * Ближайшее кратное число
 * Для расстановки квадратов в том же месте
 * Используется Decimal.js из-за проблем с точностью в js
 */
const nearestMultiple = (number: Decimal, multiple: Decimal): Decimal => {
  const quotient = number.div(multiple);
  const rounded = quotient.floor();
  return multiple.times(rounded);
};

/**
 * Примерные размеры квадрата в градусах для москвы
 * Примерный размер квадрата 1 гектар
 * Точность уменьшена из-за проблем с вычислениями
 */
const blockSideY: Decimal = new Decimal("0.000899320"); //долгота
const blockSideX: Decimal = new Decimal("0.001595636"); //широта
// const blockSideY: Decimal = new Decimal('0.0008993203637245381') //долгота
// const blockSideX: Decimal = new Decimal('0.0015956362690193982') //широта

class Grid {
  /** Пустая сетка */
  static EMPTY: Grid = new Grid(featureCollection([]), [0, 0, 0, 0], "empty");

  grid: FeatureCollection;
  coordinates: BBOXInaccurate;
  key: string;
  options: GridOptions;

  constructor(
    grid: FeatureCollection,
    coordinates: BBOXInaccurate,
    key: string,
    options: GridOptions = {}
  ) {
    this.grid = grid;
    this.coordinates = coordinates;
    this.key = key;
    this.options = options;
    if (this.options.active === undefined) {
      this.options.active = false;
    }
  }

  coordinatesToAccurate(): BBOXAccurate {
    return <BBOXAccurate>this.coordinates.map((it) => new Decimal(it));
  }

  /**
   * Создание сетки FeatureCollection если таковой нет
   * @return {Grid} this
   */
  createGrid(options: CreateGridOptions = {}): Grid {
    if (this.grid.features.length === 0 && this.key !== Grid.EMPTY.key) {
      this.grid = squareGrid(
        this.coordinatesToAccurate(),
        options
      ) as FeatureCollection;
    }
    return this;
  }

  isOverlapping(other: Grid): boolean {
    return isOverlapping(this.coordinates, other.coordinates);
  }
}

/**
 * Точка с координатами кратными размеру квадрата
 */
class Point {
  lng: Decimal;
  lat: Decimal;

  constructor(lng: Decimal, lat: Decimal) {
    this.lng = nearestMultiple(lng, blockSideX);
    this.lat = nearestMultiple(lat, blockSideY);
  }

  static fromLatLng = (latLng: LatLng): Point =>
    new Point(new Decimal(latLng.lng), new Decimal(latLng.lat));

  /**
   * Квадрат для первой выбранной точки, когда выделена только она
   */
  singleGrid(): Grid {
    const key = this.lat + "" + this.lng;
    const bbox: BBOXAccurate = [
      this.lng,
      this.lat,
      this.lng.plus(blockSideX),
      this.lat.plus(blockSideY),
    ];
    const features: BBOXInaccurate = [
      this.lng.toNumber(),
      this.lat.toNumber(),
      this.lng.plus(blockSideX).toNumber(),
      this.lat.plus(blockSideY).toNumber(),
    ];
    return new Grid(squareGrid(bbox)!!, features, key);
  }

  toString(): string {
    return this.lng + "" + this.lat;
  }

  equals(other: Point | any): boolean {
    if (!(other instanceof Point)) {
      return false;
    }
    return this.lng.eq(other.lng) && this.lat.eq(other.lat);
  }
}

/**
 * Квадраты между двумя точками
 */
const gridBetween = (
  first: Point,
  second: Point,
  options: CreateGridOptions = {}
): Grid | null => {
  const minX = Decimal.min(first.lng, second.lng);
  const minY = Decimal.min(first.lat, second.lat);
  const maxX = Decimal.max(first.lng, second.lng).add(blockSideX);
  const maxY = Decimal.max(first.lat, second.lat).add(blockSideY);

  const bbox: BBOXAccurate = [minX, minY, maxX, maxY];
  const features: BBOXInaccurate = [
    minX.toNumber(),
    minY.toNumber(),
    maxX.toNumber(),
    maxY.toNumber(),
  ];

  const key = first.toString() + "-" + second.toString();
  const func = options.accurate ? squareGridAccurate : squareGrid;
  const grid = func(bbox, options);
  if (grid === null) {
    return null;
  }
  return new Grid(grid, features, key);
};

/**
 * Копирует функционал
 * @see {squareGrid}
 * Для селектора из-за необходимости точно вычислить количество покупаемых квадратов
 * Использует Decimal.js из-за чего имеет худшую производительность и не используется
 * для отрисовки иных квадратов
 */
const squareGridAccurate = (bbox: BBOXAccurate): FeatureCollection | null => {
  const results = [];
  const west = bbox[0];
  const south = bbox[1];
  const east = bbox[2];
  const north = bbox[3];

  const cellWidthDeg = blockSideX;
  const cellHeightDeg = blockSideY;

  // rows & columns
  const bboxWidth = east.minus(west);
  const bboxHeight = north.minus(south);
  const columns = Decimal.ceil(bboxWidth.div(blockSideX)).toNumber();
  const rows = Decimal.ceil(bboxHeight.div(blockSideY)).toNumber();

  if (columns > MAX_PLOT_SIZE || rows > MAX_PLOT_SIZE) {
    return null;
  }

  let currentX = west;
  // let currentX = nearestMultiple(west, cellWidthDeg) //+ deltaX;
  for (let column = 0; column < columns; column++) {
    let currentY = south; //+ deltaY;
    for (let row = 0; row < rows; row++) {
      const cellPoly = polygon(
        [
          [
            [currentX.toNumber(), currentY.toNumber()],
            [currentX.toNumber(), currentY.plus(cellHeightDeg).toNumber()],
            [
              currentX.plus(cellWidthDeg).toNumber(),
              currentY.plus(cellHeightDeg).toNumber(),
            ],
            [currentX.plus(cellWidthDeg).toNumber(), currentY.toNumber()],
            [currentX.toNumber(), currentY.toNumber()],
          ],
        ],
        null
      );
      results.push(cellPoly);
      currentY = currentY.plus(cellHeightDeg);
    }
    currentX = currentX.plus(cellWidthDeg);
  }
  return featureCollection(results);
};

/**
 * Вне зависимости от начальных координат необходимо
 * чтобы каждый квадрат был на своем месте
 * Стандартная функция из turf не позволяет этого сделать т.к.
 * в зависимости от долготы(Y) один градус широты представляет разное
 * расстояние в метрах.
 *
 * В связи с этим размер квадрата выбран оптимальный для москвы и применяется на любой другой территории
 * @see {blockSideY, blockSideX}
 *
 * options.inside - создавать только внутри выбранной области, для невозможности покупки территории вне рашки
 * options.outside - создавать только ВНЕ выбранной области, для невозможности покупки занятой территории
 */
const squareGrid = (
  bbox: BBOXAccurate,
  options: CreateGridOptions = {}
): FeatureCollection | null => {
  let {
    // round = Decimal.ceil,
    limit = false,
    // inside = null,
    outside = null,
  } = options;

  const results = [];
  const west = bbox[0];
  const south = bbox[1];
  const east = bbox[2];
  const north = bbox[3];

  const cellWidthDeg = blockSideX.toNumber();
  const cellHeightDeg = blockSideY.toNumber();

  // rows & columns
  const bboxWidth = east.minus(west);
  const bboxHeight = north.minus(south);
  const columns = Decimal.ceil(bboxWidth.div(blockSideX)).toNumber();
  const rows = Decimal.ceil(bboxHeight.div(blockSideY)).toNumber();

  if (limit && (columns > MAX_PLOT_SIZE || rows > MAX_PLOT_SIZE)) {
    return null;
  }

  let currentX = west.toNumber();
  // let currentX = nearestMultiple(west, cellWidthDeg) //+ deltaX;
  for (let column = 0; column < columns; column++) {
    let currentY = south.toNumber(); //+ deltaY;
    for (let row = 0; row < rows; row++) {
      const cellPoly = polygon(
        [
          [
            [currentX, currentY],
            [currentX, currentY + cellHeightDeg],
            [currentX + cellWidthDeg, currentY + cellHeightDeg],
            [currentX + cellWidthDeg, currentY],
            [currentX, currentY],
          ],
        ],
        null
      ); //options.properties
      if (outside) {
        //options.mask TODO mask: boundaries.features[0],
        // if (!intersect(outside, cellPoly)) {
        //     results.push(cellPoly)
        // }
      } else {
        results.push(cellPoly);
      }
      currentY += cellHeightDeg;
    }
    currentX += cellWidthDeg;
  }
  return featureCollection(results);
};

/**
 * Соприкасаются ли 2 участка
 * Используя координаты их крайних точек
 * @param first minX,minY,maxX,maxY
 * @param second minX,minY,maxX,maxY
 */
const isOverlapping = (first: number[], second: number[]): boolean =>
  first[0] < second[2] &&
  second[0] < first[2] &&
  first[1] < second[3] &&
  second[1] < first[3];

/**
 * Количество блоков в выбранной области
 * С учетом купленных участков
 */
const calculatePlotSize = (
  grid: Grid,
  notInside: Grid[] | null = null
): number => {
  if (notInside !== null) {
    //Отфильтровать только сетки, которые соприкасаются с нужной используя более быстрый метод
    const intersecting = notInside
      .filter((it) => it.isOverlapping(grid))
      .map((it) => it.createGrid().coordinates);

    if (intersecting.length === 0) {
      return grid.grid.features.length;
    }
    return grid.grid.features.filter((feature) => {
      // @ts-ignore
      const x = feature.geometry.coordinates[0].map((it) => it[0]);
      // @ts-ignore
      const y = feature.geometry.coordinates[0].map((it) => it[1]);
      const bbox = [min(x), min(y), max(x), max(y)] as number[];

      return !intersecting.some((it) => isOverlapping(bbox, it));
    }).length;
  }
  return grid.grid.features.length;
};

export {
  nearestMultiple,
  blockSideY,
  blockSideX,
  squareGrid,
  Point,
  Grid,
  gridBetween,
  calculatePlotSize,
  LatLng,
  BBOXAccurate,
  BBOXInaccurate,
};
