import React, { createContext, useContext, useEffect, useState } from "react";
import { getAxios, getErrorMessage, PLOTS_GET_ALL } from "../util/util";
import { useLoader } from "./Loader";
import toast from "react-hot-toast";
import {
  blockSideX,
  blockSideY,
  Grid,
  gridBetween,
  Point,
} from "../util/gridUtil";
import { featureCollection } from "@turf/turf";
import Decimal from "decimal.js";
import L, { LatLng } from "leaflet";
import { useMap } from "react-leaflet";

const PlotContext = createContext<PlotContextValue | null>(null);

const usePlot = (): PlotContextValue => {
  const context = useContext(PlotContext);
  if (!context) {
    throw new Error("usePlot must be used inside PlotContext");
  }
  return context;
};

type PlotContextValue = {
  sold: Grid[];
  selection: Grid | null;
  setSelection: (n: Grid | null) => void;
  firstPoint: LatLng | null;
  secondPoint: LatLng | null;
  setFirstPoint: (n: LatLng | null) => void;
  setSecondPoint: (n: LatLng | null) => void;
  orderOpen: boolean;
  setOrderOpen: (n: boolean) => void;
  loadPlots: () => void;
};

type Props = {
  children: React.ReactNode;
};

type PlotFetchType = {
  id: number;
  min_x: string;
  min_y: string;
  max_x: string;
  max_y: string;
  color: string;
  active: boolean;
  created_at: string;
  updated_at: string;
};

const mskBounds = {
  topLeft: new LatLng(55.86374, 37.40312),
  bottomRight: new LatLng(55.66536, 37.8188),
};

const isInsideMsk = (point: Point): boolean => {
  return (
    point.lat.greaterThanOrEqualTo(new Decimal(mskBounds.bottomRight.lat)) &&
    point.lat.lessThanOrEqualTo(new Decimal(mskBounds.topLeft.lat)) &&
    point.lng.greaterThanOrEqualTo(new Decimal(mskBounds.topLeft.lng)) &&
    point.lng.lessThanOrEqualTo(new Decimal(mskBounds.bottomRight.lng))
  );
};

const PlotContextProvider = ({ children }: Props) => {
  const [sold, setSold] = useState<Grid[]>([]);
  const [selection, setSelection] = useState<Grid | null>(null);
  const [firstPoint, setFirstPoint] = useState<LatLng | null>(null);
  const [secondPoint, setSecondPoint] = useState<LatLng | null>(null);
  const [orderOpen, setOrderOpen] = useState<boolean>(false);
  const { setLoading } = useLoader();
  const map = useMap();

  const findAdjacentAvailablePlots = (
    sold: Grid[],
    start: Grid
  ): [Point, Point] | null => {
    const directions = [
      [blockSideX, 0],
      [0, blockSideY],
      [-blockSideX, 0],
      [0, -blockSideY],
    ];
    const MAX_SEARCH_DISTANCE = 200;
    for (let direction of directions) {
      let distance = 1;
      while (distance < MAX_SEARCH_DISTANCE) {
        let firstAdjacent = new Point(
          new Decimal(start.coordinates[0]).plus(
            new Decimal(direction[0]).times(distance)
          ),
          new Decimal(start.coordinates[1]).plus(
            new Decimal(direction[1]).times(distance)
          )
        );
        let secondAdjacent = new Point(
          firstAdjacent.lng.plus(direction[0]),
          firstAdjacent.lat.plus(direction[1])
        );
        if (
          !isPlotSold(sold, firstAdjacent) &&
          !isPlotSold(sold, secondAdjacent) &&
          isInsideMsk(firstAdjacent) &&
          isInsideMsk(secondAdjacent)
        ) {
          return [firstAdjacent, secondAdjacent];
        }
        distance++;
      }
    }
    return null;
  };

  const isPlotSold = (sold: Grid[], point: Point): boolean => {
    return sold.some((plot) => {
      const plotData = plot.options as PlotFetchType;
      return (
        point.lng.greaterThanOrEqualTo(new Decimal(plotData.min_x)) &&
        point.lng.lessThanOrEqualTo(new Decimal(plotData.max_x)) &&
        point.lat.greaterThanOrEqualTo(new Decimal(plotData.min_y)) &&
        point.lat.lessThanOrEqualTo(new Decimal(plotData.max_y))
      );
    });
  };

  const loadPlots = () => {
    setLoading(true);
    getAxios()
      .get(PLOTS_GET_ALL)
      .then((r) => {
        const soldPlots = r.data.map((it: PlotFetchType) => {
          return new Grid(
            featureCollection([]),
            [
              new Decimal(it.min_x).toNumber(),
              new Decimal(it.min_y).toNumber(),
              new Decimal(it.max_x).toNumber(),
              new Decimal(it.max_y).toNumber(),
            ],
            it.min_y + it.min_x + it.max_x + it.max_y,
            it
          );
        });
        setSold(soldPlots);

        const params: URLSearchParams = new URLSearchParams(
          document.location.search
        );
        const zoom: number = params.has("s") ? parseInt(params.get("s")!) : 15;
        if (zoom < 15) {
          toast.dismiss();
          return;
        }

        let adjacentPlots = null;
        let attempt = 0;
        while (!adjacentPlots && attempt < soldPlots.length) {
          const startingPlot = soldPlots[attempt];
          adjacentPlots = findAdjacentAvailablePlots(soldPlots, startingPlot);
          attempt++;
        }

        if (adjacentPlots) {
          const [firstAdjacent, secondAdjacent] = adjacentPlots;
          setFirstPoint(
            new L.LatLng(
              firstAdjacent.lat.toNumber(),
              firstAdjacent.lng.toNumber()
            )
          );
          setSecondPoint(
            new L.LatLng(
              secondAdjacent.lat.toNumber(),
              secondAdjacent.lng.toNumber()
            )
          );
          const newGrid = gridBetween(firstAdjacent, secondAdjacent, {
            accurate: true,
          });
          setSelection(newGrid);
          map.setView(
            [firstAdjacent.lat.toNumber(), firstAdjacent.lng.toNumber()],
            map.getZoom()
          );
        }
      })
      .catch((e) => toast.error(getErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  map.on("zoomend", () => {
    const zoom = map.getZoom();
    if (zoom < 15) {
      toast.remove();
    }
  });

  useEffect(() => loadPlots(), []);

  const value: PlotContextValue = {
    sold,
    selection,
    setSelection,
    firstPoint,
    secondPoint,
    setFirstPoint,
    setSecondPoint,
    orderOpen,
    setOrderOpen,
    loadPlots,
  };

  return <PlotContext.Provider value={value}>{children}</PlotContext.Provider>;
};

export default PlotContextProvider;
export { usePlot };
