import React, {CSSProperties, useState} from "react"
import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link
} from "@mui/material";
import {Close, NavigateNext} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  zIndex: {
    modal: 1400, 
  },
});

const content = [
    {
        id: 'text',
        title: 'about_project',
        items: {
            'about_1_title': {
                part_1: 'about_1_text',
                link_text: '',
                link: '',
                part_2: ''
            },
            'about_2_title': {
                part_1: 'about_2_text_1',
                link_text: 'about_2_text_link',
                link: '',
                part_2: 'about_2_text_2'
            },
        }
    }
];

interface GreetingProps {
    open: boolean,
    firstTime: boolean,
    close: () => void
}

const videoContainerStyle = {
    position: 'relative',
    minHeight: '400px',
    height: 0
}

/** Информация о проекте при первом посещении */
const Greeting = ({open, firstTime, close}: GreetingProps) => {
    const [page, setPage] = useState<number>(0);
    const [expandedGroup, setExpandedGroup] = useState<number>(0)
    const {t} = useTranslation()

    const pageContent = content[page];

    const previousPage = () => {
        setPage(page - 1)
    }

    const nextPage = () => {
        if (page === content.length - 1) {
            close()
        } else {
            setPage(page + 1)
        }
    }

    const handleClose = () => {
        close();
        setPage(0);
        setExpandedGroup(0);
    }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth
        open={open}
        onClose={() => !firstTime && handleClose()}
        PaperProps={{
          style: { zIndex: "1400 !important" },
        }}
      >
        <DialogTitle>
          <span>{t(pageContent.title)}</span>
          {(!firstTime || page !== 0) && (
            <IconButton
              onClick={handleClose}
              size="small"
              sx={{
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              <Close />
            </IconButton>
          )}
        </DialogTitle>
        {/*{pageContent.id === 'video' && (
            <DialogContent sx={videoContainerStyle}>
                {pageContent.content}
            </DialogContent>
        )}*/}
        {pageContent.id === "text" && (
          <DialogContent>
            <Grid
              display="flex"
              container={true}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              {Object.entries(pageContent.items || {}).map(
                ([title, text], i) => {
                  const arrowStyle: CSSProperties = {
                    transition: "0.3s",
                    transform: "rotate(-90deg)",
                  };
                  if (i === expandedGroup) {
                    arrowStyle.transform = "rotate(90deg)";
                  }
                  return [
                    <Grid key={`${title}-${i}-title`} item={true}>
                      <Button
                        endIcon={<NavigateNext style={arrowStyle} />}
                        onClick={() => setExpandedGroup(i)}
                      >
                        {t(title)}
                      </Button>
                    </Grid>,
                    <Grid key={`${title}-${i}-text`} item={true}>
                      <Collapse in={i === expandedGroup}>
                        {/* Для возможности добавить перенос строки */}
                        <span
                          dangerouslySetInnerHTML={{ __html: t(text.part_1) }}
                        />
                        <Link
                          href="https://www.instagram.com/mapa_peremogy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t(text.link_text)}
                        </Link>
                        {t(text.part_2)}
                      </Collapse>
                    </Grid>,
                  ];
                }
              )}
            </Grid>
          </DialogContent>
        )}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "60px",
          }}
        >
          <IconButton
            onClick={previousPage}
            disabled={page === 0}
            style={{ transform: "rotate(180deg)" }}
          >
            <NavigateNext />
          </IconButton>
          <IconButton onClick={nextPage} disabled={page === content.length - 1}>
            <NavigateNext />
          </IconButton>
          <Button onClick={close}>{t("close")}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default Greeting
