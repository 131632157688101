import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CardGiftcard, DoneOutlined, HelpOutline } from "@mui/icons-material";
import toast from "react-hot-toast";
import OrderSentModal from "./OrderSentModal";
import { useLoader } from "../../context/Loader";
import {
  CREATE_ORDER,
  emailValid,
  getAxios,
  getCurrentLanguage,
  getErrorMessage,
  LANG_EN,
  SQUARE_PRICE_UAH,
  SQUARE_PRICE_USD,
} from "../../util/util";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { usePlot } from "../../context/PlotContext";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";
import colors from "../../util/styles/colors";
import borders from "../../util/styles/borders";
import linerGradient from "../../util/styles/functions/linerGradient";
import boxShadows from "../../util/styles/boxShadows";
import { styled } from "@mui/material/styles";

const availableColors: string[] = [
  "#f5f0e0",
  "#bab4b5",
  "#f07800",
  "#9bb7d4",
  "#926aa6",
  "#b55a30",
  "#00a170",
  "#d2386c",
  "#efe1ce",
  "#9a8b4f",
  "#a0daa9",
];

const PayButton = styled(Button)({
  background: linerGradient(
    colors.gradients.info.main,
    colors.gradients.info.state
  ),
  color: colors.white.main,
  border: `${borders.borderWidth["1"]} solid ${colors.gradients.info.state}`,
  borderRadius: borders.borderRadius.lg,
  boxShadow: boxShadows.colored.button,
  "&:hover": {
    background: linerGradient(
      colors.gradients.infoHover.main,
      colors.gradients.infoHover.state
    ),
    border: `${borders.borderWidth["1"]} solid ${colors.gradients.infoHover.state}`,
  },
});

const OrderModal = () => {
  const [sentOpen, setSentOpen] = useState<boolean>(false);

  const [color, setColor] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [gift, setGift] = useState<boolean>(false);

  const { setLoading } = useLoader();
  const { loadPlots } = usePlot();

  const { orderOpen, setOrderOpen, selection } = usePlot();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { t, i18n } = useTranslation();

  const { loggedIn, user } = useAuth();

  const closeSentModal = (): void => {
    setSentOpen(false);
    clear();
  };

  const clear = (): void => {
    setColor("");
    setName(user ? user.name : "");
    setEmail("");
    setMessage("");
  };

  const send = (): void => {
    if (name.length < 1) {
      toast.error(t("order.enter_name"));
      return;
    }
    let sendEmail: string = email;
    if (user && !gift) {
      sendEmail = user.email;
    }
    if (sendEmail.length < 4 || !emailValid(sendEmail)) {
      toast.error(t("order.enter_email"));
      return;
    }
    if (color.length === 0) {
      toast.error(t(t("order.enter_color")));
      return;
    }
    setLoading(true);
    if (executeRecaptcha) {
      executeRecaptcha("newOrder")
        .then((key: string): void => {
          getAxios(true)
            .post(CREATE_ORDER, {
              color,
              name,
              email: sendEmail,
              message,
              min: selection!!.coordinates.slice(0, 2),
              max: selection!!.coordinates.slice(2),
              captcha: key,
              language: getCurrentLanguage(),
            })
            .then((response) => {
              const paymentUrl = response.data.paymentUrl;
              if (paymentUrl) {
                window.location.href = paymentUrl;
                setOrderOpen(false);
                loadPlots();
              } else {
                toast.error("Ошибка получения ссылки на оплату");
              }
            })
            .catch((error) => {
              toast.error(getErrorMessage(error));
            })
            .finally(() => setLoading(false));
        })
        .catch((): void => {
          toast.error(t("captcha_error"));
          setLoading(false);
        });
    } else {
      toast.error(t("captcha_error"));
      setLoading(false);
    }
  };

  const close = (): void => {
    clear();
    setOrderOpen(false);
  };

  useEffect(() => {
    if (orderOpen) {
      const randomColor =
        availableColors[Math.floor(Math.random() * availableColors.length)];
      setColor(randomColor);
    }
  }, [orderOpen, availableColors]);

  useEffect((): void => {
    if (user) {
      setName(user.name);
    }
  }, [user]);

  return (
    <>
      <OrderSentModal open={sentOpen} email={email} close={closeSentModal} />
      <Dialog maxWidth="xs" open={orderOpen} onClose={close}>
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          {t("order.size", { count: selection?.grid?.features?.length })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Grid spacing={1} container direction="column">
            <Grid item>
              <Typography>{t("order.total")}:</Typography>
              <Typography fontWeight="bold">
                {selection !== null &&
                  selection.grid !== null &&
                  selection.grid!.features.length *
                    ((i18n.language || "").includes(LANG_EN)
                      ? SQUARE_PRICE_USD
                      : SQUARE_PRICE_UAH)}{" "}
                {t("currency")}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                disabled={!!loggedIn}
                required
                error={email.length !== 0 && !emailValid(email)}
                sx={{ width: "240px", maxWidth: "100%" }}
                value={loggedIn ? user!!.email : email}
                InputProps={{ readOnly: !!loggedIn }}
                onChange={(e): void => {
                  if (loggedIn) {
                    return;
                  }
                  setEmail(e.target.value);
                }}
                size="small"
                label={t("email")}
                type="email"
              />
            </Grid>
            {loggedIn && (
              <Grid item textAlign="center">
                <Button
                  onClick={(): void => setGift(!gift)}
                  color="primary"
                  endIcon={<CardGiftcard />}
                >
                  {t("order.gift_button")}
                </Button>
                <Tooltip title={t("order.gift_info")} placement="top">
                  <IconButton>
                    <HelpOutline />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {loggedIn && gift && (
              <Grid item>
                <TextField
                  required
                  error={email.length !== 0 && !emailValid(email)}
                  sx={{ width: "240px" }}
                  value={email}
                  onChange={(e): void => setEmail(e.target.value)}
                  size="small"
                  label={t("order.gift_email")}
                  type="email"
                />
              </Grid>
            )}
            <Grid item>
              <TextField
                required
                sx={{ width: "240px" }}
                value={name}
                inputProps={{ maxLength: 20, minLength: 1 }}
                onChange={(e): void => setName(e.target.value)}
                size="small"
                label={t("name")}
                type="text"
              />
            </Grid>
            <Grid item sx={{ marginBottom: "25px" }}>
              <TextField
                sx={{ width: "240px" }}
                multiline
                rows={2}
                value={message}
                inputProps={{ maxLength: 150 }}
                onChange={(e): void => setMessage(e.target.value)}
                size="small"
                label={t("order.enter_message")}
                type="text"
              />
            </Grid>
            <Grid item>
              <Grid
                maxWidth="300px"
                spacing="4px"
                display="flex"
                justifyContent="center"
                container
              >
                {availableColors.map((it: string): JSX.Element => {
                  return (
                    <Grid item key={it}>
                      <ToggleButton
                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                          setColor((e.target as HTMLButtonElement).value)
                        }
                        size="small"
                        sx={{
                          width: "35px",
                          height: "35px",
                          backgroundColor: it,
                          border: color === it ? "solid 2px" : "",
                          "&:hover": {
                            backgroundColor: it,
                            border: "solid 2px",
                          },
                        }}
                        value={it}
                      >
                        {color === it && <DoneOutlined />}
                      </ToggleButton>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{t("order.cancel")}</Button>
          <PayButton className="button-pay" onClick={send}>
            {t("order.pay")}
          </PayButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderModal;
