import {Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import {Cached, Login, ScheduleSend} from "@mui/icons-material";
import {useAuth} from "../../context/AuthContext";
import {useLoader} from "../../context/Loader";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean,
    close: () => void
}

const LoginModal = ({open, close}: Props): JSX.Element => {

    const [email, setEmail] = useState('')
    const [otp, setOTP] = useState('')

    const [otpHidden, setOTPHidden] = useState(true)

    const {login, sendOTP} = useAuth()
    const {setLoading} = useLoader()
    const {t} = useTranslation()

    const trySendOTP = (): void => {
        setLoading(true)
        sendOTP(email, (success: boolean): void => {
            setLoading(false)
            if (success) {
                setOTPHidden(false)
            }
        })
    }

    const tryLogin = (): void => {
        setLoading(true)
        if (otp.length !== 6) {
            toast.error(t('auth.otp_length_error'))
            return
        }
        login(email, otp, (success: boolean): void => {
            setLoading(false)
            if (success) {
                setEmail('')
                setOTP('')
                setOTPHidden(true)
                close()
            }
        })
    }

    return <Dialog keepMounted={false} open={open} onClose={close}>
        <DialogTitle sx={{textAlign: 'center'}}>
            {t('auth.login_dialog_title')}
        </DialogTitle>
        <DialogContent>
            <Grid spacing={2} direction='column' container={true}>
                <Grid item={true}>
                    <TextField variant='standard' required={true} value={email}
                               onChange={e => setEmail(e.target.value)}
                               type='email' label='Email'/>
                </Grid>
                <Grid item={true}>
                    <Grid direction='row' alignItems='center' container={true}>
                        <Grid xs={10} item={true}>
                            <TextField sx={otpHidden ? {display: 'none'} : {}} hidden={otpHidden} variant='standard'
                                       required={true} value={otp}
                                       onChange={e => setOTP(e.target.value)}
                                       type='password' label='OTP'/>
                        </Grid>
                        <Grid xs={2} textAlign='center' item={true}>
                            <Tooltip title={'Resend OTP'} placement='top'>
                                <IconButton onClick={trySendOTP} sx={otpHidden ? {display: 'none'} : {}}>
                                    <Cached/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid textAlign='right' item={true}>
                    {otpHidden ?
                        <Button onClick={trySendOTP} startIcon={<ScheduleSend/>}>{t('auth.send_otp_btn')}</Button>
                        : <Button onClick={tryLogin} startIcon={<Login/>}>{t('auth.login_btn')}</Button>}
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}
export default LoginModal