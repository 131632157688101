import * as React from 'react';
import {useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';

import borders from '../../util/styles/borders';
import boxShadows from '../../util/styles/boxShadows'
import typographyStyles from '../../util/styles/typography';
import linerGradient from '../../util/styles/functions/linerGradient';
import colors from '../../util/styles/colors';
import {usePlot} from "../../context/PlotContext";
import toast from "react-hot-toast";
import {calculatePlotSize} from "../../util/gridUtil";
import {LANG_EN, SQUARE_PRICE_UAH, SQUARE_PRICE_USD} from "../../util/util";
import {useTranslation} from "react-i18next";
import {AddLocationAltOutlined} from "@mui/icons-material";

const infoBoxTitle = {
    ...typographyStyles.baseDisplayProperties,
    color: colors.text.main,
    fontSize: typographyStyles.baseProperties.fontSizeSM,
    textAlign: 'right'
};
const infoBoxCount = {
    ...typographyStyles.baseDisplayProperties,
    fontSize: typographyStyles.baseProperties.fontSize2XL,
    fontWeight: typographyStyles.baseProperties.fontWeightBold,
    textAlign: 'right'
};
const total = {
    ...typographyStyles.baseDisplayProperties,
    fontSize: typographyStyles.baseProperties.fontSizeSM,
};
const BuyButton = styled(Button)({
    background: linerGradient(colors.gradients.info.main, colors.gradients.info.state),
    color: colors.white.main,
    border: `${borders.borderWidth['1']} solid ${colors.gradients.info.state}`,
    borderRadius: borders.borderRadius.lg,
    boxShadow: boxShadows.colored.button,
    '&:hover': {
        background: linerGradient(colors.gradients.infoHover.main, colors.gradients.infoHover.state),
        border: `${borders.borderWidth['1']} solid ${colors.gradients.infoHover.state}`,
    }
});

const ToggleButton = styled(IconButton)({
    minWidth: '44px',
    width: '44px',
    height: '44px',
    marginRight: '20px',
    background: linerGradient(colors.gradients.primary.main, colors.gradients.primary.state),
    border: `${borders.borderWidth['1']} solid ${colors.gradients.primary.state}`,
    color: colors.white.main,
    borderRadius: borders.borderRadius.lg,
    boxShadow: boxShadows.colored.red,
    '&:hover': {
        background: linerGradient(colors.gradients.primaryHover.main, colors.gradients.primaryHover.state),
        border: `${borders.borderWidth['1']} solid ${colors.gradients.primaryHover.state}`,
    }
});

const InfoBox = () => {
    const [show, setShow] = useState('');

    const {selection, setOrderOpen, sold} = usePlot();
    const {t, i18n} = useTranslation();

    const count = selection === null ? 0 : calculatePlotSize(selection, sold);

    const toggleCard = (prevState: string): string => {
        if (prevState === 'hide') {
            return 'show';
        }

        if (prevState === '' && !count) {
            return 'show';
        }

        return 'hide';
    }

    const openOrderModal = () => {
        if (isNaN(count) || count < 1) {
            toast.error(t('selection.select_first'))
            return
        }
        if (count !== selection?.grid?.features?.length) {
            toast.error(t('selection.intersection'))
            return
        }
        setOrderOpen(true)
    }

    if (!count && show === 'hide') {
        setShow('');
    }

    const currentPrice: number = (i18n.language || '').includes(LANG_EN) ? ((count || 0) * SQUARE_PRICE_USD) : ((count || 0) * SQUARE_PRICE_UAH);

    return <>
        <IconButton sx={{
            minWidth: '44px',
            width: '44px',
            height: '44px',
            borderRadius: borders.borderRadius.lg,
            boxShadow: boxShadows.colored.white,
            color: 'black',
            backgroundColor: 'white'
            // boxShadow: 'rgb(77,140,63)',
            // backgroundColor: 'rgb(77,140,63)',
        }} onClick={() => setShow(toggleCard)}>
            <AddLocationAltOutlined/>
        </IconButton>
        <Card hidden={show === 'hide' || (show === '' && !count)} sx={{
            position: 'fixed',
            top: '57px',
            left: '16px',
            minWidth: 242,
            borderRadius: borders.borderRadius.xl
        }}>
            <CardContent>
                <Typography sx={infoBoxTitle}
                            gutterBottom>
                    {t('selection.amount')}:
                </Typography>
                <Typography sx={infoBoxCount}>
                    {count || 0}
                </Typography>
            </CardContent>
            <Divider/>
            <CardActions sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px'
            }}>
                <Typography sx={total}>
                    <b>{t('selection.price')}</b>
                    {' '}
                    {currentPrice}
                    {' '}
                    {t('currency')}
                </Typography>
                <BuyButton className='button-purchase' onClick={openOrderModal} variant="outlined">
                    <Typography sx={{...typographyStyles.button}}>
                        {t('selection.buy')}
                    </Typography>
                </BuyButton>
            </CardActions>
        </Card>
    </>
}
export default InfoBox;
