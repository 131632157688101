import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import OrderRow from "./OrderRow";
import {Order} from "../../util/types/commonTypes";
import {useTranslation} from "react-i18next";
import {useLoader} from "../../context/Loader";
import {deserializePlot, getAxios, getErrorMessage, MY_ORDERS} from "../../util/util";
import toast from "react-hot-toast";
import {isMobile} from 'react-device-detect';

interface Props {
    isOpen: boolean,
    close: () => void
}

const OrderListModal = ({isOpen, close}: Props): JSX.Element => {
    const [list, setList] = useState<Order[] | null>(null)

    const {t} = useTranslation()
    const {setLoading} = useLoader()

    useEffect((): void => {
        if (!isOpen) {
            return
        }
        setLoading(true)
        getAxios(true).get<Order[]>(MY_ORDERS)
            .then((res): Order[] => res.data.filter((it: Order) => it.plot !== null))
            .then((list: Order[]): void => {
                list.forEach((it: Order): void => {
                    if (it.plot === null) {
                        return
                    }
                    it.plot = deserializePlot(it.plot)
                })
                setList(list)
            })
            .catch(e => {
                toast.error(getErrorMessage(e))
            }).finally(() => setLoading(false))
    }, [isOpen])

    return <Dialog fullScreen={isMobile} open={isOpen} onClose={close} fullWidth={true}>
        <DialogTitle>
            {t('plot_list.title')}
        </DialogTitle>
        <DialogContent>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>{t('plot_list.id')}</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>{t('plot_list.size')}</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>{t('plot_list.show')}</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>{t('plot_list.download')}</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>{t('plot_list.share')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list === null ? null : list.map(it => <OrderRow key={it.id} order={it} closeModal={close}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>{t('plot_list.close')}</Button>
        </DialogActions>
    </Dialog>
}
export default OrderListModal