/**
 * The base z-indexes for project.
 * You can add new z-indexes using this file.
 * You can customize the z-indexes for the entire project using this file.
 */

const zIndexes = {
    values: {
        mainBar: 1001,
    },
};

export default zIndexes;
