import i18n from "./i18n";
import axios, {AxiosInstance, AxiosProgressEvent, RawAxiosRequestHeaders} from "axios";
import {getJWT} from "./auth";
import {saveAs} from "file-saver";
import {round} from "lodash";
import {Plot} from "./types/commonTypes";

export const backendAddress = process.env.REACT_APP_BACKEND_ADDRESS
export const apiAddress = backendAddress + '/api'
export const isLocalEnvironment: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export const CREATE_ORDER = 'order' //POST
export const PLOTS_GET_ALL = 'plot' //GET
export const FEEDBACK = 'feedback' //POST, GET
export const LOGOUT = 'auth/logout' //GET
export const SEND_OTP = 'auth/send-otp' //POST
export const LOGIN = 'auth/login' //POST
export const ME = 'auth/me' //GET
export const MY_ORDERS = 'order/my' //GET
export const DOWNLOAD_ORDER = 'order/certificate' //GET
export const GET_PINS = 'pin' //GET

export const SQUARE_PRICE_UAH: number = 50; //UAH
export const SQUARE_PRICE_USD: number = 2; //USD

export const LANG_EN: 'en' = 'en'
export const LANG_UK: 'uk' = 'uk'

/**
 * Получить язык в нужном формате
 */
export const getCurrentLanguage = (): 'en' | 'uk' => {
    const lng: string | undefined = i18n.language
    if (!lng) {
        return LANG_EN
    }
    if (lng.includes(LANG_EN)) {
        return LANG_EN
    }
    return LANG_UK
}

export const getAxios = (withJWT: boolean = false): AxiosInstance => {
    const headers: RawAxiosRequestHeaders = {
        'Accept-Language': getCurrentLanguage()
    }
    if (withJWT && getJWT()) {
        headers.Authorization = 'Bearer ' + getJWT()
    }
    return axios.create({
        baseURL: apiAddress + '/',
        headers
    })
}

export const emailValid = (email: string) => email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

export const getErrorMessage = (obj: any): string => {
    if ('request' in obj) {
        if (!obj.request || !obj.request.response) {
            return 'Request error'
        }
    }
    if ('response' in obj) {
        obj = obj.response
        if ('data' in obj) {
            obj = obj.data
        }
    }
    if ('message' in obj && obj.message.length > 0) {
        return obj.message
    }
    if (obj.request && obj.request.response && 'message' in obj.request.response && obj.request.response.message.length > 0) {
        return obj.request.response.message
    }
    if ('error' in obj) {
        const err = obj.error
        if (typeof err === 'object' && 'message' in err) {
            return err.message
        }
        return err
    }
    if ('exception' in obj) {
        return obj.exception
    }
    return obj.toString()
}

/**
 * Plot comes with coordinates as strings due to json float serialize issues
 */
export const deserializePlot = (plot: Plot): Plot => {
    plot.center = [Number(plot.center[0]), Number(plot.center[1])]
    plot.max_x = Number(plot.max_x)
    plot.max_y = Number(plot.max_y)
    plot.min_x = Number(plot.min_x)
    plot.min_y = Number(plot.min_y)
    return plot
}

export const downloadPDF = async (url: string, progressCallback: (percent: number) => void): Promise<void> => {
    const response = await axios.get(url, {
        responseType: 'blob',

        onDownloadProgress: (progressEvent: AxiosProgressEvent): void => {
            let progress = (progressEvent.loaded / progressEvent.total!!) * 100
            progress = round(progress)
            progressCallback(progress)
        }
    })

    let filename = response.headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, '');
    filename = decodeURIComponent(filename.replace(/\+/g, ' '))
    const blob = new Blob([response.data], {type: 'application/pdf'});

    saveAs(blob, filename)
}
