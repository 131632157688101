import React, {Context, createContext, useContext, useEffect, useState} from "react";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {getJWT, removeJWT, setJWT} from "../util/auth";
import {getAxios, getErrorMessage, LOGIN, LOGOUT, ME, SEND_OTP} from "../util/util";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {User} from "../util/types/commonTypes";

const AuthContext: Context<AuthContextValue | null> = createContext<AuthContextValue | null>(null)

const useAuth = (): AuthContextValue => {
    const context: AuthContextValue | null = useContext<AuthContextValue | null>(AuthContext)
    if (!context) throw new Error('useAuth must be used inside AuthContext')
    return context
}

type Props = {
    children: ReactJSXElement | ReactJSXElement[]
}

type AuthContextValue = {
    user: User | null | undefined,
    loggedIn: boolean | undefined, // false - auth failed, true - authorized, undefined - pending
    logout: () => void,
    sendOTP: (email: string, loadingCallback: (success: boolean) => void) => void,
    login: (email: string, otp: string, loadingCallback: (success: boolean) => void) => void
}

const AuthContextProvider = ({children}: Props) => {
    // false - no auth token, undefined - pending
    const [authorized, setAuthorized] = useState<boolean | undefined>(getJWT() === null ? false : undefined)
    // null - no auth token, undefined - pending
    const [user, setUser] = useState<User | null | undefined>(getJWT() === null ? null : undefined)
    const {t} = useTranslation()

    const tryToken = (): void => {
        if (getJWT() === null) {
            return
        }
        getAxios(true).get<User>(ME, {headers: {Authorization: `Bearer ${getJWT()}`}})
            .then((res): void => {
                setAuthorized(true)
                setUser(res.data)
            })
            .catch(e => {
                if (e.response) {
                    if (e.response.code === 403) {
                        removeJWT()
                    }
                }
                setAuthorized(false)
            })
    }

    useEffect((): void => {
        tryToken()
    }, [])

    const logout = (): void => {
        getAxios(true).get(LOGOUT).then(() => toast.success(t('auth.success')))
        removeJWT()
        setAuthorized(false)
    }

    const sendOTP = (email: string, loadingCallback: (success: boolean) => void): void => {
        if (!/\S+@\S+\.\S+/.test(email)) {
            toast.error(t('wrong_email'))
            loadingCallback(false)
            return
        }
        getAxios().post(SEND_OTP, {email})
            .then((): void => {
                toast.success(t('auth.otp_sent'))
                loadingCallback(true)
            })
            .catch((e): void => {
                toast.error(getErrorMessage(e))
                loadingCallback(false)
            })
    }

    const login = (email: string, otp: string, loadingCallback: (success: boolean) => void): void => {
        getAxios().post(LOGIN, {email, otp})
            .then(res => {
                setJWT(res.data.access_token)
                setUser(res.data.user)
                setAuthorized(true)
                toast.success(t('auth.success'))
                loadingCallback(true)
            })
            .catch(e => {
                toast.error(e.response.message)
                loadingCallback(false)
            })
    }

    const value: AuthContextValue = {user, loggedIn: authorized, logout, sendOTP, login}

    return <AuthContext.Provider children={children} value={value}/>
}

export default AuthContextProvider
export {useAuth}
