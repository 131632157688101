import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Fab,
  Link,
  ListItemIcon,
  ListItemText,
  Menu as MenuMaterial,
  MenuItem,
  MenuList,
  MenuProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  Instagram,
  Language,
  List,
  Login,
  Logout,
  Menu as MenuIcon,
  QuizOutlined,
  Slideshow,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Greeting from "../greeting/Greeting";
import Info from "../feedback/Feedback";
import i18n from "../../util/i18n";
import borders from "../../util/styles/borders";
import boxShadows from "../../util/styles/boxShadows";
import Divider from "@mui/material/Divider";
import { useAuth } from "../../context/AuthContext";
import LoginModal from "../login/LoginModal";
import OderListModal from "../cabinet/OrderListModal";
import CustomToastComponent from "../../map/toast/ToastComponent";
import toast from "react-hot-toast";

const StyledMenu = styled((props: MenuProps) => <MenuMaterial {...props} />)(
  () => ({
    "& .MuiPaper-root": {
      borderRadius: borders.borderRadius.xl,
      width: "200px",
      marginTop: "7px",
      "@media (min-width: 600px)": {
        marginTop: "3px",
      },
    },
  })
);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  "& .MuiToggleButtonGroup-grouped": {
    height: "30px",
    border: 0,
    borderRadius: 0,
    "&:first-of-type": {
      borderRight: "1px solid",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
}));

const Menu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [greetingOpen, setGreetingOpen] = useState<boolean>(
    localStorage.getItem("greeting") !== "1"
  );
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false); 

  const [loginOpen, setLoginOpen] = useState(false);
  const [orderListOpen, setOrderListOpen] = useState(false);

  const { t } = useTranslation();
  const { loggedIn, logout } = useAuth();

  const anchor = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!greetingOpen) {
      toast.custom(
        <CustomToastComponent message={t("selection.random_plots")} />,
        {
          position: "bottom-center",
          duration: 8000,
        }
      );
    }
  }, [greetingOpen]); 

  const loginData = () => {
    if (loggedIn === undefined) {
      return (
        <MenuItem>
          <ListItemIcon>
            <CircularProgress size={24} />
          </ListItemIcon>
          <ListItemText>{t("menu.wait")}</ListItemText>
        </MenuItem>
      );
    }
    if (loggedIn) {
      return (
        <MenuItem
          onClick={() => {
            setOrderListOpen(true);
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <List />
          </ListItemIcon>
          <ListItemText>{t("menu.orders")}</ListItemText>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          onClick={() => {
            setLoginOpen(true);
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Login />
          </ListItemIcon>
          <ListItemText>{t("menu.login")}</ListItemText>
        </MenuItem>
      );
    }
  };

  return (
    <>
      <OderListModal
        isOpen={orderListOpen}
        close={() => setOrderListOpen(false)}
      />
      <LoginModal open={loginOpen} close={() => setLoginOpen(false)} />
      <Fab
        ref={anchor}
        onClick={() => setOpen(!open)}
        sx={{
          minWidth: "44px",
          width: "44px",
          height: "44px",
          borderRadius: borders.borderRadius.lg,
          boxShadow: boxShadows.colored.white,
          backgroundColor: "#ffffff",
        }}
      >
        <MenuIcon />
      </Fab>
      <StyledMenu
        onClose={() => setOpen(false)}
        open={open}
        anchorEl={anchor.current}
      >
        <MenuList>
          {/* Login or cabinet */}
          {loginData()}
          <MenuItem
            onClick={() => {
              setGreetingOpen(true);
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <Slideshow />
            </ListItemIcon>
            <ListItemText>{t("about_project")}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setInfoOpen(true);
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <QuizOutlined />
            </ListItemIcon>
            <ListItemText>{t("feedback")}</ListItemText>
          </MenuItem>
          <MenuItem
            component="a"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/mapa_peremogy"
          >
            <ListItemIcon>
              <Instagram />
            </ListItemIcon>
            <ListItemText>{t("insta")}</ListItemText>
          </MenuItem>
          {loggedIn === true ? (
            <MenuItem component="button" onClick={logout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText>{t("menu.logout")}</ListItemText>
            </MenuItem>
          ) : null}
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "0 16px 5px",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "30px",
              }}
            >
              <Language />
            </ListItemIcon>
            <ListItemText>
              <StyledToggleButtonGroup
                value={i18n.language}
                defaultValue="uk"
                exclusive
                size="small"
                onChange={(_, value) => i18n.changeLanguage(value)}
                aria-label="lng"
              >
                <ToggleButton value="uk" aria-label="UA">
                  {t("lang_ukr")}
                </ToggleButton>
                <ToggleButton value="en" aria-label="EN">
                  {t("lang_eng")}
                </ToggleButton>
              </StyledToggleButtonGroup>
            </ListItemText>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 18px 3px",
            }}
          >
            <Link
              href="https://view.officeapps.live.com/op/embed.aspx?src=https://victorymap.com.ua/docs/Privacy_Policy.docx?v=2"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              underline="hover"
              color="inherit"
              sx={{
                fontSize: "0.75rem",
              }}
            >
              {t("private_policy")}
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0 18px",
            }}
          >
            <Link
              href="https://view.officeapps.live.com/op/embed.aspx?src=https://victorymap.com.ua/docs/Public_Offer.docx?v=2"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              underline="hover"
              color="inherit"
              sx={{
                fontSize: "0.75rem",
              }}
            >
              {t("public_offer")}
            </Link>
          </Box>
        </MenuList>
      </StyledMenu>
      <Info open={infoOpen} close={() => setInfoOpen(false)} />
      <Greeting
        open={greetingOpen}
        firstTime={localStorage.getItem("greeting") !== "1"}
        close={() => {
          setGreetingOpen(false);
          localStorage.setItem("greeting", "1");
        }}
      />
      {showToast && (
        <CustomToastComponent message={t("selection.random_plots")} />
      )}
    </>
  );
};

export default Menu;
