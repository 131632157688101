import React from "react";
import "./ToastComponent.css"; 

type CustomToastComponentProps = {
  message: string; 
};

const CustomToastComponent = ({ message }: CustomToastComponentProps) => {
    return (
      <div className="custom-toast">
        <img
          src="/logo192.png"
          alt="logo"
          width="34px"
          style={{ marginRight: "10px" }}
        />
        {message}
      </div>
    );
};

export default CustomToastComponent;
