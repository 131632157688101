import React from "react";
import {useTranslation} from "react-i18next";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import FeedbackForm from "./FeedbackForm";

interface FeedbackProps {
    open: boolean,
    close: () => void
}

const Feedback = ({open, close}: FeedbackProps) => {
    const {t} = useTranslation()

    return <Dialog
        fullWidth
        open={open}
        onClose={close}
    >
        <DialogTitle>
            {t('feedback')}
            <IconButton
                onClick={close}
                size="small"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px'
                }}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <FeedbackForm/>
        </DialogContent>
    </Dialog>
}

export default Feedback;
