import { GeoJSON, useMapEvents } from "react-leaflet";
import React, { useEffect, useMemo, useState } from "react";
import {
  BBOXAccurate,
  BBOXInaccurate,
  blockSideX,
  blockSideY,
  LatLng,
  nearestMultiple,
  squareGrid,
} from "../../util/gridUtil";
import Decimal from "decimal.js";
import { FeatureCollection } from "@turf/turf";
import { SHOW_ZOOM } from "../../App";
import { LeafletEvent, Map } from "leaflet";
import { isIOS, isSafari } from "react-device-detect";
import { round } from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "../../util/i18n";
import { useTranslation } from "react-i18next";

const gridStyle = {
  weight: 0.5,
};

interface Props {
  defaultZoom: number;
}

/**
 * Все клетки
 */
const All = ({ defaultZoom }: Props): JSX.Element | null => {
  /* Обновить координаты в адресной строке */
  const updateURLParams = (map: Map): void => {
    if (isSafari) {
      return;
    }
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    params.set(
      "c",
      map.getCenter().lat.toFixed(4) + "-" + map.getCenter().lng.toFixed(4)
    );
    params.set("s", round(map.getZoom(), 0).toString());
    window.history.replaceState({}, "", `?${params}`);
  };

  // Toast notification to enlarge the map
  const [toastId, setToastId] = useState<string | number | null>(null);

  const { t } = useTranslation();

      const map: Map = useMapEvents({
        zoomend: (e: LeafletEvent): void => {
          updateURLParams(e.target);
          const newZoom = e.target.getZoom();

          if (newZoom < SHOW_ZOOM) {
            setCenter(null);
            if (toastId) {
              toast.dismiss(toastId);
            }
            const newToastId = toast.warn(t("toast_zoom"));
            setToastId(newToastId);
          } else {
            if (toastId) {
              toast.dismiss(toastId);
              setToastId(null);
            }
          }
        },
        moveend: (e: LeafletEvent): void => {
          updateURLParams(e.target);
          if (e.target.getZoom() < 15) {
            return;
          }
          const newCenter = e.target.getCenter();
          if (
            center === null ||
            Math.abs(newCenter.lat - center.lat) > blockSideY.toNumber() * 5 ||
            Math.abs(newCenter.lng - center.lng) > blockSideX.toNumber() * 10
          ) {
            setCenter(newCenter);
          }
        },
      });

  const [center, setCenter] = useState<LatLng | null>(
    defaultZoom >= SHOW_ZOOM ? map.getCenter() : null
  );

  const grid = useMemo(() => {
    if (center === null) {
      return null;
    }
    const startLat: number = nearestMultiple(
      new Decimal(center.lat),
      blockSideY
    ).toNumber();
    let startLng: number = nearestMultiple(
      new Decimal(center.lng),
      blockSideX
    ).toNumber();
    const addHeight: number = blockSideY.toNumber() * 20;
    const addWidth: number = blockSideX.toNumber() * 34;
    const bbox: BBOXInaccurate = [
      startLng - addWidth,
      startLat - addHeight,
      startLng + addWidth,
      startLat + addHeight,
    ];
    return squareGrid(
      bbox.map((it: number): Decimal => new Decimal(it)) as BBOXAccurate
    ) as FeatureCollection;
  }, [center]);

  /* Fix iOS address bar */
  useEffect((): void => {
    if (!isIOS) {
      return;
    }
    document
      .querySelector("div.leaflet-control-zoom")
      ?.setAttribute("style", "margin-bottom: 75px !important;");
  });

  if (grid === null) {
    return null;
  }

  return <GeoJSON key={`all-${center}`} data={grid} style={gridStyle} />;
};
export default All;
