import {usePlot} from "../../context/PlotContext";
import {GeoJSON, Pane, Popup, useMapEvents} from "react-leaflet";
import React, {useMemo, useState} from "react";
import {SHOW_ZOOM} from "../../App";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";
import {LatLng, LeafletEvent} from "leaflet";
import {blockSideX, blockSideY} from "../../util/gridUtil";
import {isMobile} from "react-device-detect";

const abs = Math.abs;

interface UpdatableCommentProps {
    text: string;
    translate: boolean; //переводить ли текст, нужно для сообщения об ожидании оплаты или при отсутствии сообщения
    pane: string; //имя родителя для выставления ему z-index, чтобы попап не был под другими участками
    zoom: number;
}

//Для перевода описания участка без перезагрузки страницы
const UpdatablePopup = ({
                            text,
                            translate,
                            pane,
                            zoom,
                        }: UpdatableCommentProps) => {
    //чтобы попап не перекрывался другими участками
    useMapEvents({
        popupopen: (it: any) => {
            if (it.popup.options.pane !== pane) {
                return;
            }
            const element = document.querySelector(`div[class*='${pane}']`);
            if (element) {
                element.setAttribute("style", "z-index: 8002");
            }
        },
        popupclose: (it) => {
            const element = document.querySelector(
                `div[class*='${it.popup.options.pane}']`
            );
            if (element) {
                element.setAttribute("style", "z-index: 8000");
            }
        },
    });

    const {t} = useTranslation();
    if (zoom < 15) {
        return null;
    }
    if (!translate) {
        return <Popup>{text}</Popup>;
    }
    return <Popup>{t(text)}</Popup>;
};

interface Props {
    defaultZoom: number;
}

//Проданные участки
const Sold = ({defaultZoom}: Props): JSX.Element | null => {
    const {sold} = usePlot();

    const [zoom, setZoom] = useState(defaultZoom);
    const [center, setCenter] = useState<LatLng | null>(null)

    useMapEvents({
        "zoom": (e) => setZoom(e.target.getZoom()),
        moveend: (e: LeafletEvent): void => {
            const newCenter = e.target.getCenter();
            if (
                center === null ||
                Math.abs(newCenter.lat - center.lat) > blockSideY.toNumber() * 2 ||
                Math.abs(newCenter.lng - center.lng) > blockSideX.toNumber() * 2
            ) {
                setCenter(newCenter);
            }
        }
    });

    const grids = useMemo(() => {
        return sold.map((it) => {
            /** @param it {Grid} */
            const style = {
                color: it.options.active ? (it.options.color as string) : "red", //Он красный если еще не оплачен
                weight: 2,
            };
            it.createGrid({limit: false});
            let comment: string = isEmpty(it.options.comment)
                ? "message_sold"
                : (it.options.comment as string);
            let active: boolean = it.options.active as boolean;

            if (!active) {
                comment = "waiting_payment";
            }
            const translate = !active || isEmpty(it.options.comment);
            const center = new LatLng(
                it.coordinates[1] + (it.coordinates[3] - it.coordinates[1]) / 2,
                it.coordinates[0] + (it.coordinates[2] - it.coordinates[0]) / 2);

            return {
                visible: (from: LatLng | null): boolean => {
                    if (from === null) {
                        return false;
                    }
                    const y = isMobile ? 21 : 23;
                    const x = isMobile ? 21 : 34;
                    // console.log(abs(from.lat - center.lat),blockSideY.toNumber() * 10)
                    return abs(from.lat - center.lat) < blockSideY.toNumber() * y && abs(from.lng - center.lng) < blockSideX.toNumber() * x;
                },
                comp: <Pane style={{zIndex: 8000}} name={it.key} key={it.key}>
                    <GeoJSON style={style} data={it.grid}>
                        <UpdatablePopup
                            text={comment}
                            translate={translate}
                            pane={it.key}
                            zoom={zoom}
                        />
                    </GeoJSON>
                </Pane>
            }
        });
    }, [sold, zoom]);

    if (zoom < SHOW_ZOOM) {
        return null;
    }
    return <React.Fragment>{grids.filter(it => it.visible(center)).map(it => it.comp)}</React.Fragment>;
};
export default Sold;
