import React, {createContext, useContext, useState} from "react";
import {Backdrop, CircularProgress} from "@mui/material";

const LoaderContext = createContext<LoaderContextValue | null>(null)

const useLoader = (): LoaderContextValue => {
    const context = useContext(LoaderContext)
    if (!context) throw new Error("useLoader must be used inside LoaderProvider")
    return context
}

type Props = {
    children: JSX.Element
}

type LoaderContextValue = {
    isOpen: boolean,
    setLoading: (n: boolean) => void
}

const Loader = ({children}: Props) => {
    const [loading, setLoading] = useState<boolean>(true)

    const value: LoaderContextValue = {isOpen: loading, setLoading: setLoading}

    return <LoaderContext.Provider
        value={value}>
        <Backdrop style={{zIndex: 9000}} open={loading}>
            <CircularProgress/>
        </Backdrop>
        {children}
    </LoaderContext.Provider>
}

export {useLoader, Loader}
