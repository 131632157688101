import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {useAuth} from "../../context/AuthContext";


type Props = {
    email: string
    close: () => void
    open: boolean
}

const OrderSentModal = ({email, close, open}: Props) => {
    const {t} = useTranslation()
    const {user} = useAuth()

    let realEmail: string = email
    if (user) {
        realEmail = user.email
    }
    return <Dialog open={open}>
        <DialogTitle>
            {t('order.sent.title')}
        </DialogTitle>
        <DialogContent>
            <Typography textAlign={"center"}>
                <Trans values={{email: realEmail}} i18nKey='order.sent.description'>
                    <strong/><strong/>
                </Trans>
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>{t('close')}</Button>
        </DialogActions>
    </Dialog>
}
export default OrderSentModal
